<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit
        </v-icon>Create Project
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="projectName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Project Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="projectNumber"
                  class="field-style"
                  label="Project Number"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectType"
                  class="field-style"
                  :items="projectTypes"
                  label="Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <address-form
                  ref="addressForm"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Project Start Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minStartDate"
                    @input="$refs.startDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="endDialog"
                  v-model="endDateDialog"
                  :return-value.sync="endDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Project End Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.endDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="currency"
                  class="field-style"
                  :items="formattedCurrenciesList"
                  :rules="[(v) => !!v || 'This field is required']"
                  item-value="text"
                  item-text="text"
                  label="Currency"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="projectValue"
                  class="field-style"
                  label="Project Value*"
                  hint="Estimated project cost"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectTimeZone"
                  class="field-style"
                  :items="timeZonesByName"
                  label="Time Zone"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="projectStatus"
                  class="field-style"
                  :items="statusList"
                  label="Project Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createProject()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import AddressForm from 'src/views/dashboard/component/AddressForm';
import moment from 'moment';

export default {
 name: 'CreateProject',
  components: {
    'centre-spinner': spinner,
    'address-form': AddressForm,
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      date: '',
      startDateDialog: false,
      endDateDialog: false,
      statusList: [
        {
          name: 'Active',
          id: 'active',
        },
        {
          name: 'Pending',
          id: 'pending',
        },
        {
          name: 'Completed',
          id: 'completed',
        },
      ],
      projectName: '',
      projectNumber: '',
      projectType: '',
      startDate: '',
      endDate: '',
      currency: null,
      projectValue: '',
      projectStatus: 'pending',
      projectTimeZone: '',
      address: {},
      minStartDate: moment().format('YYYY-MM-DD'),
    };
  },
computed: {
  timeZones () {
    return this.$store.getters['userprofile/getTimeZones'];
  },
  timeZonesByName () {
    return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
  },
  projectTypes () {
    return this.$store.getters['projects/getProjectTypes'];
  },
  currencyList () {
    return this.$store.getters['userprofile/getCurrencyList'];
  },
  formattedCurrenciesList () {
    return Object.keys(this.currencyList).map((key) => ({ text: key, value: this.currencyList[key] }));
  },
  minEndDate () {
    return moment(this.startDate).format('YYYY-MM-DD');
  },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    this.loading = true;
    await this.getAllTimeZone();
    await this.getProjectType();
    await this.getCurrencyList();
    this.loading = false;
  },
  methods: {
    async getAllTimeZone () {
      await this.$store.dispatch('userprofile/fetchTimeZones');
    },
    async getProjectType () {
      await this.$store.dispatch('projects/fetchProjectTypes');
    },
    async getCurrencyList () {
      await this.$store.dispatch('userprofile/fetchCurrency');
    },
    assiginAddress (address) {
      this.address = address;
    },
    async createProject () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
        message: 'Please fill the required fields before proceeding.',
        type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      this.$refs.addressForm.getAddress();
      await this.$store.dispatch('projects/createProject', {
        name: this.projectName,
        project_number: this.projectNumber,
        project_value: this.projectValue,
        start_date: this.startDate,
        end_date: this.endDate,
        project_status: this.projectStatus,
        type: this.projectType,
        address: this.address,
        timezone: this.projectTimeZone,
        currency: this.currency,
      }).then(response => {
          this.$store.dispatch('alert/onAlert', {
          message: 'New project has been created successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
</style>
